<template>
  <div class="projectAddressPageBox">
    <ul>
      <li class="title">
        平台
      </li>
      <li>
        <a
          name="tapd"
          href="https://www.tapd.cn/my_worktable?left_tree=1#&filter_close=true"
          ADD_DATE="1654656131"
          ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACQklEQVQ4jY2Uy29NURTGf98+59zbVKQVqZJ4NwgpWgYiQpCmSKhHUpFOMJJKNE1MDTqokUHLP2DORCoiCPFWr6gIogwMijTBdRPce+65dy+Dvm7bBGu2s/b3y1pfvr21tzc2/q9ywEcZT8zpjuTvlmQ+/IugCAwLvTHsEdjDwHgPpVyQqfx2sUuFlrO5beUAD2SA9zI9Nuy+yV4lUZKN4qjGAreuiG91hGuK1XE30OdQfQgMyHiJdA/juVfhiw+DSpeoXtLRKAk3mrOVMmaDnAFCbc1n7Kb3cX3owlRTZga/Zv0oNnpXapJFm1SiAcc8IA1CU3YzbHtFGG+VXF249iuZ1wWqC873YNrwDyMTYNjh3ppYZNgSN1BVaGE+WcSHvykFTzHaDGuKXbQH84OCGmfiVPIpv8DM+sfNFIPAwKSxZflCkLre11nx7mqHYuTqgJkOWO9x7QHBAHBNZieDwDcLuoG4jLAqKibLy5grAEIASUe8lW74yvSBy8f0G2BfT07IDQF1o4LZkt8APNt1ztLmC8sEuFH8HKQTxZjxXETZis+IF5Pd15bWCxZUxD+rBIuZAACwI1VKDo4dLnapgNm9coATjbmh3FwfpGoNq50KSOOs40BPfum48971A98nJmChk2sw2UKhqqkAMFaXUHvrBQtGCNEg8I6RmA8JPXABicRyIDUdAJjscPIpvxngUidZMzttcMiwpt9J1PLje/oWqGHs/rTXKFSDdHzreeu/LeX74Ep5f39vfrfH7YSRX+APr5/uCT3s/TYAAAAASUVORK5CYII="
          target="_blank"
        >TAPD平台</a>
      </li>
      <li class="title">
        后端中间件页面
      </li>
      <li>
        <a
          href="http://112.74.161.242:7091/#/TransactionInfo"
          ADD_DATE="1661856047"
          ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAIAAACQkWg2AAACE0lEQVQ4jU2SzUtVURTFf+uce/U+ErUM0ww0zB6G6SBCMAibRRJEBUGjJs0D/4CCatKwaZMm5SAaOGvSKPogKoloYGL2gSkYkS8z37v3nN3gvWft8V57s35rielIfcwQTooGAQResmgGEggiuIR/IyBuRdLYWSKaVX6bKSVV/RYILAEDBxFBLU4OFxfG6G1XjFpcC3deFB/WMlJtX3WYkEmiyvGD4fpJjfb6aoH3HBtsvXmK7rYahcCaAoHJzJBNHVKL9PRj7fL9cGU2rK7/KXfZVLlGEVxdIuoeDISPpSRY1Ke1ovIjq1RbZt5uljttueJxFk0IjAQznARWuPm1MLwrXBpPRvryG4/CgycpWUICKZiBcHgmrjUhaem7xgdsajQ72p+eHYtZls+tKMfjQEIAnomrTf+2WXXPllTEotxj+zqzE0MktvV80eV4Gpzcf4IIxuaWHr/T7Puwuy1UNmNfh1uuhIXV1CVm5nC4xnbB3g67dTq/fS4f7s8XVrKLd5O5FZcmfrArYtEaL5Q0IpZVtuxANwOdfn61mP9Wa29TKcXM8rBdAzBL6lSVaOOX7r1metLOH0lH+kKWuv07qRXh5WePd036ro7VmRmtejjnsHDmsA3tSWLky3p15hVvVlpJZdGQwMR0QCI2kqdKWgo9OwK41Q3lVU9rI9jt8gnqKRqSK5HH5OtPjwmPMjNTk6mAv1l39i1Odz7nAAAAAElFTkSuQmCC"
          target="_blank"
        >Seata控制台</a>
      </li>
      <li>
        <a
          href="http://112.74.162.127:8848/nacos/#/configurationManagement?dataId=&group=&appName=&namespace=&pageSize=&pageNo="
          ADD_DATE="1659782018"
          ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAB9UlEQVQ4jc2SzUuUYRTFz32e9/Wd0Up0aqohBEtiFjJ9KFaLWbQo0L1tktrlv9BADLpoCFokZsW4iNqV7QMrCDcSOKlRuAn6AENo0KYYx/freU+LqUycdhEduJvL757FuQf4T0TBFDVIwQgVRqi2I1Q/RkBqgLJ53NDz9/2fGQsQ9k0w4TgYpoNsqLEaRrhfEnn6y0iEB+fCAXH0UNjC1sAyM7W16mRFpCI9RXbsDDEtO5D2gIXI5gFJyB4vxKi7jsLSOfHTJVOQdpXz1s2KiUuZCZUxfvjK2/D7VYuLO0qzo/YVp2YvyvHap8+dwQaKykJeVbE3/cLk7YTKmS9R4d3z950fu/SRcNk7iybVbWv7Ok7fJrNjvAEAGKQGAExRdz9wD2WecCwzT6ZnzeUtYQLY98G7m1r2/e1pA8ASqC0nRh1pIQBBQ46gKBPgsR3jpRPjPIlHYnqKbO49ilt2G94E5eCmcXGlab+6enjeFDD+1oFIlHrtntG7rAugPKyHGGFampH2wUVjSUp2I+ka5FUS1172SpAuBQW0Wzm/alaimJSjpMoYL1z0a16/AEDfBBNOHMORRtY0YdUQ9+bOy7Mtb1xwB8R2hsI4WwOLM7W1b5OVY22Vv1OkepWhMIgIo6jDItEmKfyZPgACUBBEEGFD43+q7wDS8apIz4VIAAAAAElFTkSuQmCC"
          target="_blank"
        >Nacos控制台</a>
      </li>
      <li>
        <a
          href="http://112.74.161.242:9999/#/"
          ADD_DATE="1660097861"
          target="_blank"
        >RocketMq控制台</a>
      </li>
      <li>
        <a
          href="http://192.168.110.119:8888/"
          ADD_DATE="1661824616"
          ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAADL0lEQVQ4jW2Sy28TZxTFzzcPPya249hp7Tg49iTIdlISIaUqitqIpCqiC9QHC5CQqJRWrYSUJQIVseii/wHsumi7iCitKjUttFQR6gZSCE6qJIAFchi7GT+SsT1j49ge2zNfV47iiru8ur+rc869BK+oSCDS3+d3XmVZxCbHo5OEYW2EQMsphWvL8c1rsizXO7Pc/+Hz584tOAThQ0+vDfNzpwWHnSOgJkzTdDz6J/FlqVR+3+fznVxdXW0BAHsQ/vzTz34Mh8JnnTajeOnCGQ9nNgg1WjCNFmAaCPj6bJMTo0PLK+sTW+ntmwDAdODZ6enjKUn6WM5k6Kn3plxtvYaf/niAe48SXQozeYW1WfmZ2OCgt0vBaDR2a2b2Xb9SKJKjkQDtdQqcw27BxGgYlFIQQgAAocHXcWL6TXuhXM39vfb4wb4Ct9vdm83lYBrmy1Cgz2rhGIhBH1jejlSmAABISlmAEDxNymibNNplQSvtcoMevt5svGyWtGqj019/ksDtpfsAAKWkYXllE/X6HqxWXji4gJycOeYiRsM++1aMcTrsfGfBkUgY83MfAQCmJsfwNLmNN6IiCIFy8Iw0vZ3NXZk/e5jn2L5XvAaKahUFVQXHstD1JuScEu8KMRQ49O+vS/emQkNDbn+/ax8kDIu1jee489cKajUd2Z0CklKmsHj34WVN06r7Gdz87ffFtcSLM7vFchsgKFf2AAC63kR84xlGwgG4XAIufPIB1hNbyVQqle8KEQAkSYr/fOuuxll7wLAMHq4lYOFZnD99AtGRII5ERXxz4081kVLyHWbfgiiKoYHgyH21xQ5k5WwzNhxgTEqZ698uom20q3JOyV//7pd6fP1FjzUYGevtEbzKTvYOAcBEImNf2zyvzfePv+NsNXV4Wmrp7YkIv3NjAaMM9CW9ZTB+7+7xSm24Ranl+0PjvKmVasXU5mFOFMVxVzh20T4wzJcLOYBS1ADPD8tPMMex+WM84w/xXGVB3fOrhtEQLBxntg3oevVZOp3e4SRJeuwOxiqm0faC0q70nxNLRdBr1dtOr/rFV5eOytl8aSmRbpsbm1ZD17cAmP8BdCZfU/oK4QYAAAAASUVORK5CYII="
          target="_blank"
        >内网Jenkins控制台</a>
      </li>
      <li>
        <a
          href="http://210.22.25.57:8087/login?from=%2F"
          ADD_DATE="1661824629"
          ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAADL0lEQVQ4jW2Sy28TZxTFzzcPPya249hp7Tg49iTIdlISIaUqitqIpCqiC9QHC5CQqJRWrYSUJQIVseii/wHsumi7iCitKjUttFQR6gZSCE6qJIAFchi7GT+SsT1j49ge2zNfV47iiru8ur+rc869BK+oSCDS3+d3XmVZxCbHo5OEYW2EQMsphWvL8c1rsizXO7Pc/+Hz584tOAThQ0+vDfNzpwWHnSOgJkzTdDz6J/FlqVR+3+fznVxdXW0BAHsQ/vzTz34Mh8JnnTajeOnCGQ9nNgg1WjCNFmAaCPj6bJMTo0PLK+sTW+ntmwDAdODZ6enjKUn6WM5k6Kn3plxtvYaf/niAe48SXQozeYW1WfmZ2OCgt0vBaDR2a2b2Xb9SKJKjkQDtdQqcw27BxGgYlFIQQgAAocHXcWL6TXuhXM39vfb4wb4Ct9vdm83lYBrmy1Cgz2rhGIhBH1jejlSmAABISlmAEDxNymibNNplQSvtcoMevt5svGyWtGqj019/ksDtpfsAAKWkYXllE/X6HqxWXji4gJycOeYiRsM++1aMcTrsfGfBkUgY83MfAQCmJsfwNLmNN6IiCIFy8Iw0vZ3NXZk/e5jn2L5XvAaKahUFVQXHstD1JuScEu8KMRQ49O+vS/emQkNDbn+/ax8kDIu1jee489cKajUd2Z0CklKmsHj34WVN06r7Gdz87ffFtcSLM7vFchsgKFf2AAC63kR84xlGwgG4XAIufPIB1hNbyVQqle8KEQAkSYr/fOuuxll7wLAMHq4lYOFZnD99AtGRII5ERXxz4081kVLyHWbfgiiKoYHgyH21xQ5k5WwzNhxgTEqZ698uom20q3JOyV//7pd6fP1FjzUYGevtEbzKTvYOAcBEImNf2zyvzfePv+NsNXV4Wmrp7YkIv3NjAaMM9CW9ZTB+7+7xSm24Ranl+0PjvKmVasXU5mFOFMVxVzh20T4wzJcLOYBS1ADPD8tPMMex+WM84w/xXGVB3fOrhtEQLBxntg3oevVZOp3e4SRJeuwOxiqm0faC0q70nxNLRdBr1dtOr/rFV5eOytl8aSmRbpsbm1ZD17cAmP8BdCZfU/oK4QYAAAAASUVORK5CYII="
          target="_blank"
        >外网Jenkins控制台</a>
      </li>
      <li class="title">
        swagger页面
      </li>
      <li>
        <a
          href="http://120.77.45.206:8086/plat/approveabc/doc.html#/"
          ADD_DATE="1663815034"
          ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAClElEQVQ4jW1TTWtUZxg953nfe2+ccRqdaYiCDnTTFrpz2UW5+AMCXQnuTKnxBwT8DUJ+gCJJFy12KfgDZFAQXLS4NCuhlKqYmJiZxrn3vu9zukgqs/CsHng+zuHhHAKAABIQAPx9/8bVaFrPUN22uggAZcnXAZgkt51LP//yeHGHOMXLO2uD86ujLSVfJxHnXYbkEgmzyKXCIPfEYDsHb/c3v739aAoAFMDd7bWz5zh81CtDvX+cM0MpQPY/geYzMRQugKNBFY6bbnKosPbNTzuzSED/4PxWv4r1u1lq7PvrBc4MDZ4gOULVR//tLg+f/x5C9YX2pk2zMliqfd5uEdjgX3ev/1AU5eOmacWqb6pvEkUPhODKJALG4+9w9MdDvX/2G6w6K0heFcau667GEMKGGQOAJDihjObjMY5n/zKWUeeWlzH/OGXvyo/sulbTPx8SsYLRQghhIzqsbroskAYRBOEuilDOzg9HU+y/f6G9vUMG9DAu+wi5tSZBpNWWsl/IEkDQBQIASUGgJLRtC8+Zq6tfKrJDyk4SzBJS9guGBZALNQCCMBoAoqwqXB5fQjCDpE9zFoO9CSc2EnliJgGkUadXYEa5Z5JADCYICiRisDdm8ElVBEJyAJAASEpdorJL2ZW6RGiBWPKqCDT4JOac75mFayfqKVhgdaZEjFE6NRJBhSKCuT31HuCunHO+Z+NbD54I2h4u92Jq227+YU+pbYDcgrkTcyfkFqltcNJru+FyLwraHt968CQK4C4ONtkMv14ZxPrV019zQnQjbFGBCx6R+NVoqZo1aXKEg00t/v3lnbXBaGW0Jfg6gdgkR3YXAAQzVtEgIBG2s/9uIUyfj7OvJ0fdJb8IAEW019E+H+f/AJvEd3R++Q5SAAAAAElFTkSuQmCC"
          target="_blank"
        >开发环境-运营端</a>
      </li>
      <li>
        <a
          href="http://120.77.45.206:8086/esign/approveabc/doc.html#/"
          ADD_DATE="1663815122"
          ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAClElEQVQ4jW1TTWtUZxg953nfe2+ccRqdaYiCDnTTFrpz2UW5+AMCXQnuTKnxBwT8DUJ+gCJJFy12KfgDZFAQXLS4NCuhlKqYmJiZxrn3vu9zukgqs/CsHng+zuHhHAKAABIQAPx9/8bVaFrPUN22uggAZcnXAZgkt51LP//yeHGHOMXLO2uD86ujLSVfJxHnXYbkEgmzyKXCIPfEYDsHb/c3v739aAoAFMDd7bWz5zh81CtDvX+cM0MpQPY/geYzMRQugKNBFY6bbnKosPbNTzuzSED/4PxWv4r1u1lq7PvrBc4MDZ4gOULVR//tLg+f/x5C9YX2pk2zMliqfd5uEdjgX3ev/1AU5eOmacWqb6pvEkUPhODKJALG4+9w9MdDvX/2G6w6K0heFcau667GEMKGGQOAJDihjObjMY5n/zKWUeeWlzH/OGXvyo/sulbTPx8SsYLRQghhIzqsbroskAYRBOEuilDOzg9HU+y/f6G9vUMG9DAu+wi5tSZBpNWWsl/IEkDQBQIASUGgJLRtC8+Zq6tfKrJDyk4SzBJS9guGBZALNQCCMBoAoqwqXB5fQjCDpE9zFoO9CSc2EnliJgGkUadXYEa5Z5JADCYICiRisDdm8ElVBEJyAJAASEpdorJL2ZW6RGiBWPKqCDT4JOac75mFayfqKVhgdaZEjFE6NRJBhSKCuT31HuCunHO+Z+NbD54I2h4u92Jq227+YU+pbYDcgrkTcyfkFqltcNJru+FyLwraHt968CQK4C4ONtkMv14ZxPrV019zQnQjbFGBCx6R+NVoqZo1aXKEg00t/v3lnbXBaGW0Jfg6gdgkR3YXAAQzVtEgIBG2s/9uIUyfj7OvJ0fdJb8IAEW019E+H+f/AJvEd3R++Q5SAAAAAElFTkSuQmCC"
          target="_blank"
        >开发环境-电子签章端</a>
      </li>
      <li>
        <a
          href="http://120.77.45.206:8086/scf/approveabc/doc.html#/"
          ADD_DATE="1663815074"
          ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAClElEQVQ4jW1TTWtUZxg953nfe2+ccRqdaYiCDnTTFrpz2UW5+AMCXQnuTKnxBwT8DUJ+gCJJFy12KfgDZFAQXLS4NCuhlKqYmJiZxrn3vu9zukgqs/CsHng+zuHhHAKAABIQAPx9/8bVaFrPUN22uggAZcnXAZgkt51LP//yeHGHOMXLO2uD86ujLSVfJxHnXYbkEgmzyKXCIPfEYDsHb/c3v739aAoAFMDd7bWz5zh81CtDvX+cM0MpQPY/geYzMRQugKNBFY6bbnKosPbNTzuzSED/4PxWv4r1u1lq7PvrBc4MDZ4gOULVR//tLg+f/x5C9YX2pk2zMliqfd5uEdjgX3ev/1AU5eOmacWqb6pvEkUPhODKJALG4+9w9MdDvX/2G6w6K0heFcau667GEMKGGQOAJDihjObjMY5n/zKWUeeWlzH/OGXvyo/sulbTPx8SsYLRQghhIzqsbroskAYRBOEuilDOzg9HU+y/f6G9vUMG9DAu+wi5tSZBpNWWsl/IEkDQBQIASUGgJLRtC8+Zq6tfKrJDyk4SzBJS9guGBZALNQCCMBoAoqwqXB5fQjCDpE9zFoO9CSc2EnliJgGkUadXYEa5Z5JADCYICiRisDdm8ElVBEJyAJAASEpdorJL2ZW6RGiBWPKqCDT4JOac75mFayfqKVhgdaZEjFE6NRJBhSKCuT31HuCunHO+Z+NbD54I2h4u92Jq227+YU+pbYDcgrkTcyfkFqltcNJru+FyLwraHt968CQK4C4ONtkMv14ZxPrV019zQnQjbFGBCx6R+NVoqZo1aXKEg00t/v3lnbXBaGW0Jfg6gdgkR3YXAAQzVtEgIBG2s/9uIUyfj7OvJ0fdJb8IAEW019E+H+f/AJvEd3R++Q5SAAAAAElFTkSuQmCC"
          target="_blank"
        >开发环境-供应链金融端</a>
      </li>
      <li>
        <a
          href="http://120.77.45.206:8086/scm/doc.html#/"
          ADD_DATE="1663815095"
          ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAClElEQVQ4jW1TTWtUZxg953nfe2+ccRqdaYiCDnTTFrpz2UW5+AMCXQnuTKnxBwT8DUJ+gCJJFy12KfgDZFAQXLS4NCuhlKqYmJiZxrn3vu9zukgqs/CsHng+zuHhHAKAABIQAPx9/8bVaFrPUN22uggAZcnXAZgkt51LP//yeHGHOMXLO2uD86ujLSVfJxHnXYbkEgmzyKXCIPfEYDsHb/c3v739aAoAFMDd7bWz5zh81CtDvX+cM0MpQPY/geYzMRQugKNBFY6bbnKosPbNTzuzSED/4PxWv4r1u1lq7PvrBc4MDZ4gOULVR//tLg+f/x5C9YX2pk2zMliqfd5uEdjgX3ev/1AU5eOmacWqb6pvEkUPhODKJALG4+9w9MdDvX/2G6w6K0heFcau667GEMKGGQOAJDihjObjMY5n/zKWUeeWlzH/OGXvyo/sulbTPx8SsYLRQghhIzqsbroskAYRBOEuilDOzg9HU+y/f6G9vUMG9DAu+wi5tSZBpNWWsl/IEkDQBQIASUGgJLRtC8+Zq6tfKrJDyk4SzBJS9guGBZALNQCCMBoAoqwqXB5fQjCDpE9zFoO9CSc2EnliJgGkUadXYEa5Z5JADCYICiRisDdm8ElVBEJyAJAASEpdorJL2ZW6RGiBWPKqCDT4JOac75mFayfqKVhgdaZEjFE6NRJBhSKCuT31HuCunHO+Z+NbD54I2h4u92Jq227+YU+pbYDcgrkTcyfkFqltcNJru+FyLwraHt968CQK4C4ONtkMv14ZxPrV019zQnQjbFGBCx6R+NVoqZo1aXKEg00t/v3lnbXBaGW0Jfg6gdgkR3YXAAQzVtEgIBG2s/9uIUyfj7OvJ0fdJb8IAEW019E+H+f/AJvEd3R++Q5SAAAAAElFTkSuQmCC"
          target="_blank"
        >开发环境-商城端</a>
      </li>
      <li>
        <a
          href="http://120.77.45.206:8086/cms/approveabc/doc.html#/"
          ADD_DATE="1663815108"
          ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAClElEQVQ4jW1TTWtUZxg953nfe2+ccRqdaYiCDnTTFrpz2UW5+AMCXQnuTKnxBwT8DUJ+gCJJFy12KfgDZFAQXLS4NCuhlKqYmJiZxrn3vu9zukgqs/CsHng+zuHhHAKAABIQAPx9/8bVaFrPUN22uggAZcnXAZgkt51LP//yeHGHOMXLO2uD86ujLSVfJxHnXYbkEgmzyKXCIPfEYDsHb/c3v739aAoAFMDd7bWz5zh81CtDvX+cM0MpQPY/geYzMRQugKNBFY6bbnKosPbNTzuzSED/4PxWv4r1u1lq7PvrBc4MDZ4gOULVR//tLg+f/x5C9YX2pk2zMliqfd5uEdjgX3ev/1AU5eOmacWqb6pvEkUPhODKJALG4+9w9MdDvX/2G6w6K0heFcau667GEMKGGQOAJDihjObjMY5n/zKWUeeWlzH/OGXvyo/sulbTPx8SsYLRQghhIzqsbroskAYRBOEuilDOzg9HU+y/f6G9vUMG9DAu+wi5tSZBpNWWsl/IEkDQBQIASUGgJLRtC8+Zq6tfKrJDyk4SzBJS9guGBZALNQCCMBoAoqwqXB5fQjCDpE9zFoO9CSc2EnliJgGkUadXYEa5Z5JADCYICiRisDdm8ElVBEJyAJAASEpdorJL2ZW6RGiBWPKqCDT4JOac75mFayfqKVhgdaZEjFE6NRJBhSKCuT31HuCunHO+Z+NbD54I2h4u92Jq227+YU+pbYDcgrkTcyfkFqltcNJru+FyLwraHt968CQK4C4ONtkMv14ZxPrV019zQnQjbFGBCx6R+NVoqZo1aXKEg00t/v3lnbXBaGW0Jfg6gdgkR3YXAAQzVtEgIBG2s/9uIUyfj7OvJ0fdJb8IAEW019E+H+f/AJvEd3R++Q5SAAAAAElFTkSuQmCC"
          target="_blank"
        >开发环境-内容管理端</a>
      </li>
      <li>
        <a
          href="http://120.77.45.206:8086/plat/approveabc/doc.html#/"
          ADD_DATE="1663815135"
          ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAClElEQVQ4jW1TTWtUZxg953nfe2+ccRqdaYiCDnTTFrpz2UW5+AMCXQnuTKnxBwT8DUJ+gCJJFy12KfgDZFAQXLS4NCuhlKqYmJiZxrn3vu9zukgqs/CsHng+zuHhHAKAABIQAPx9/8bVaFrPUN22uggAZcnXAZgkt51LP//yeHGHOMXLO2uD86ujLSVfJxHnXYbkEgmzyKXCIPfEYDsHb/c3v739aAoAFMDd7bWz5zh81CtDvX+cM0MpQPY/geYzMRQugKNBFY6bbnKosPbNTzuzSED/4PxWv4r1u1lq7PvrBc4MDZ4gOULVR//tLg+f/x5C9YX2pk2zMliqfd5uEdjgX3ev/1AU5eOmacWqb6pvEkUPhODKJALG4+9w9MdDvX/2G6w6K0heFcau667GEMKGGQOAJDihjObjMY5n/zKWUeeWlzH/OGXvyo/sulbTPx8SsYLRQghhIzqsbroskAYRBOEuilDOzg9HU+y/f6G9vUMG9DAu+wi5tSZBpNWWsl/IEkDQBQIASUGgJLRtC8+Zq6tfKrJDyk4SzBJS9guGBZALNQCCMBoAoqwqXB5fQjCDpE9zFoO9CSc2EnliJgGkUadXYEa5Z5JADCYICiRisDdm8ElVBEJyAJAASEpdorJL2ZW6RGiBWPKqCDT4JOac75mFayfqKVhgdaZEjFE6NRJBhSKCuT31HuCunHO+Z+NbD54I2h4u92Jq227+YU+pbYDcgrkTcyfkFqltcNJru+FyLwraHt968CQK4C4ONtkMv14ZxPrV019zQnQjbFGBCx6R+NVoqZo1aXKEg00t/v3lnbXBaGW0Jfg6gdgkR3YXAAQzVtEgIBG2s/9uIUyfj7OvJ0fdJb8IAEW019E+H+f/AJvEd3R++Q5SAAAAAElFTkSuQmCC"
          target="_blank"
        >测试环境-运营端</a>
      </li>
      <li>
        <a
          href="http://120.77.45.206:8086/scf/approveabc/doc.html#/"
          ADD_DATE="1663815146"
          ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAClElEQVQ4jW1TTWtUZxg953nfe2+ccRqdaYiCDnTTFrpz2UW5+AMCXQnuTKnxBwT8DUJ+gCJJFy12KfgDZFAQXLS4NCuhlKqYmJiZxrn3vu9zukgqs/CsHng+zuHhHAKAABIQAPx9/8bVaFrPUN22uggAZcnXAZgkt51LP//yeHGHOMXLO2uD86ujLSVfJxHnXYbkEgmzyKXCIPfEYDsHb/c3v739aAoAFMDd7bWz5zh81CtDvX+cM0MpQPY/geYzMRQugKNBFY6bbnKosPbNTzuzSED/4PxWv4r1u1lq7PvrBc4MDZ4gOULVR//tLg+f/x5C9YX2pk2zMliqfd5uEdjgX3ev/1AU5eOmacWqb6pvEkUPhODKJALG4+9w9MdDvX/2G6w6K0heFcau667GEMKGGQOAJDihjObjMY5n/zKWUeeWlzH/OGXvyo/sulbTPx8SsYLRQghhIzqsbroskAYRBOEuilDOzg9HU+y/f6G9vUMG9DAu+wi5tSZBpNWWsl/IEkDQBQIASUGgJLRtC8+Zq6tfKrJDyk4SzBJS9guGBZALNQCCMBoAoqwqXB5fQjCDpE9zFoO9CSc2EnliJgGkUadXYEa5Z5JADCYICiRisDdm8ElVBEJyAJAASEpdorJL2ZW6RGiBWPKqCDT4JOac75mFayfqKVhgdaZEjFE6NRJBhSKCuT31HuCunHO+Z+NbD54I2h4u92Jq227+YU+pbYDcgrkTcyfkFqltcNJru+FyLwraHt968CQK4C4ONtkMv14ZxPrV019zQnQjbFGBCx6R+NVoqZo1aXKEg00t/v3lnbXBaGW0Jfg6gdgkR3YXAAQzVtEgIBG2s/9uIUyfj7OvJ0fdJb8IAEW019E+H+f/AJvEd3R++Q5SAAAAAElFTkSuQmCC"
          target="_blank"
        >测试环境-供应链金融端</a>
      </li>
      <li>
        <a
          href="http://120.77.45.206:8086/scm/doc.html#/"
          ADD_DATE="1663815162"
          ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAClElEQVQ4jW1TTWtUZxg953nfe2+ccRqdaYiCDnTTFrpz2UW5+AMCXQnuTKnxBwT8DUJ+gCJJFy12KfgDZFAQXLS4NCuhlKqYmJiZxrn3vu9zukgqs/CsHng+zuHhHAKAABIQAPx9/8bVaFrPUN22uggAZcnXAZgkt51LP//yeHGHOMXLO2uD86ujLSVfJxHnXYbkEgmzyKXCIPfEYDsHb/c3v739aAoAFMDd7bWz5zh81CtDvX+cM0MpQPY/geYzMRQugKNBFY6bbnKosPbNTzuzSED/4PxWv4r1u1lq7PvrBc4MDZ4gOULVR//tLg+f/x5C9YX2pk2zMliqfd5uEdjgX3ev/1AU5eOmacWqb6pvEkUPhODKJALG4+9w9MdDvX/2G6w6K0heFcau667GEMKGGQOAJDihjObjMY5n/zKWUeeWlzH/OGXvyo/sulbTPx8SsYLRQghhIzqsbroskAYRBOEuilDOzg9HU+y/f6G9vUMG9DAu+wi5tSZBpNWWsl/IEkDQBQIASUGgJLRtC8+Zq6tfKrJDyk4SzBJS9guGBZALNQCCMBoAoqwqXB5fQjCDpE9zFoO9CSc2EnliJgGkUadXYEa5Z5JADCYICiRisDdm8ElVBEJyAJAASEpdorJL2ZW6RGiBWPKqCDT4JOac75mFayfqKVhgdaZEjFE6NRJBhSKCuT31HuCunHO+Z+NbD54I2h4u92Jq227+YU+pbYDcgrkTcyfkFqltcNJru+FyLwraHt968CQK4C4ONtkMv14ZxPrV019zQnQjbFGBCx6R+NVoqZo1aXKEg00t/v3lnbXBaGW0Jfg6gdgkR3YXAAQzVtEgIBG2s/9uIUyfj7OvJ0fdJb8IAEW019E+H+f/AJvEd3R++Q5SAAAAAElFTkSuQmCC"
          target="_blank"
        >测试环境-商城端</a>
      </li>
      <li>
        <a
          href="http://120.77.45.206:8086/cms/approveabc/doc.html#/"
          ADD_DATE="1663815171"
          ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAClElEQVQ4jW1TTWtUZxg953nfe2+ccRqdaYiCDnTTFrpz2UW5+AMCXQnuTKnxBwT8DUJ+gCJJFy12KfgDZFAQXLS4NCuhlKqYmJiZxrn3vu9zukgqs/CsHng+zuHhHAKAABIQAPx9/8bVaFrPUN22uggAZcnXAZgkt51LP//yeHGHOMXLO2uD86ujLSVfJxHnXYbkEgmzyKXCIPfEYDsHb/c3v739aAoAFMDd7bWz5zh81CtDvX+cM0MpQPY/geYzMRQugKNBFY6bbnKosPbNTzuzSED/4PxWv4r1u1lq7PvrBc4MDZ4gOULVR//tLg+f/x5C9YX2pk2zMliqfd5uEdjgX3ev/1AU5eOmacWqb6pvEkUPhODKJALG4+9w9MdDvX/2G6w6K0heFcau667GEMKGGQOAJDihjObjMY5n/zKWUeeWlzH/OGXvyo/sulbTPx8SsYLRQghhIzqsbroskAYRBOEuilDOzg9HU+y/f6G9vUMG9DAu+wi5tSZBpNWWsl/IEkDQBQIASUGgJLRtC8+Zq6tfKrJDyk4SzBJS9guGBZALNQCCMBoAoqwqXB5fQjCDpE9zFoO9CSc2EnliJgGkUadXYEa5Z5JADCYICiRisDdm8ElVBEJyAJAASEpdorJL2ZW6RGiBWPKqCDT4JOac75mFayfqKVhgdaZEjFE6NRJBhSKCuT31HuCunHO+Z+NbD54I2h4u92Jq227+YU+pbYDcgrkTcyfkFqltcNJru+FyLwraHt968CQK4C4ONtkMv14ZxPrV019zQnQjbFGBCx6R+NVoqZo1aXKEg00t/v3lnbXBaGW0Jfg6gdgkR3YXAAQzVtEgIBG2s/9uIUyfj7OvJ0fdJb8IAEW019E+H+f/AJvEd3R++Q5SAAAAAElFTkSuQmCC"
          target="_blank"
        >测试环境-内容管理端</a>
      </li>
      <li>
        <a
          href="http://120.77.45.206:8086/esign/approveabc/doc.html#/"
          ADD_DATE="1663815179"
          ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAClElEQVQ4jW1TTWtUZxg953nfe2+ccRqdaYiCDnTTFrpz2UW5+AMCXQnuTKnxBwT8DUJ+gCJJFy12KfgDZFAQXLS4NCuhlKqYmJiZxrn3vu9zukgqs/CsHng+zuHhHAKAABIQAPx9/8bVaFrPUN22uggAZcnXAZgkt51LP//yeHGHOMXLO2uD86ujLSVfJxHnXYbkEgmzyKXCIPfEYDsHb/c3v739aAoAFMDd7bWz5zh81CtDvX+cM0MpQPY/geYzMRQugKNBFY6bbnKosPbNTzuzSED/4PxWv4r1u1lq7PvrBc4MDZ4gOULVR//tLg+f/x5C9YX2pk2zMliqfd5uEdjgX3ev/1AU5eOmacWqb6pvEkUPhODKJALG4+9w9MdDvX/2G6w6K0heFcau667GEMKGGQOAJDihjObjMY5n/zKWUeeWlzH/OGXvyo/sulbTPx8SsYLRQghhIzqsbroskAYRBOEuilDOzg9HU+y/f6G9vUMG9DAu+wi5tSZBpNWWsl/IEkDQBQIASUGgJLRtC8+Zq6tfKrJDyk4SzBJS9guGBZALNQCCMBoAoqwqXB5fQjCDpE9zFoO9CSc2EnliJgGkUadXYEa5Z5JADCYICiRisDdm8ElVBEJyAJAASEpdorJL2ZW6RGiBWPKqCDT4JOac75mFayfqKVhgdaZEjFE6NRJBhSKCuT31HuCunHO+Z+NbD54I2h4u92Jq227+YU+pbYDcgrkTcyfkFqltcNJru+FyLwraHt968CQK4C4ONtkMv14ZxPrV019zQnQjbFGBCx6R+NVoqZo1aXKEg00t/v3lnbXBaGW0Jfg6gdgkR3YXAAQzVtEgIBG2s/9uIUyfj7OvJ0fdJb8IAEW019E+H+f/AJvEd3R++Q5SAAAAAElFTkSuQmCC"
          target="_blank"
        >测试环境-电子签章端</a>
      </li>
      <li>
        <a
          href="http://112.74.164.238:8086/plat/approveabc/doc.html"
          ADD_DATE="1663815179"
          ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAClElEQVQ4jW1TTWtUZxg953nfe2+ccRqdaYiCDnTTFrpz2UW5+AMCXQnuTKnxBwT8DUJ+gCJJFy12KfgDZFAQXLS4NCuhlKqYmJiZxrn3vu9zukgqs/CsHng+zuHhHAKAABIQAPx9/8bVaFrPUN22uggAZcnXAZgkt51LP//yeHGHOMXLO2uD86ujLSVfJxHnXYbkEgmzyKXCIPfEYDsHb/c3v739aAoAFMDd7bWz5zh81CtDvX+cM0MpQPY/geYzMRQugKNBFY6bbnKosPbNTzuzSED/4PxWv4r1u1lq7PvrBc4MDZ4gOULVR//tLg+f/x5C9YX2pk2zMliqfd5uEdjgX3ev/1AU5eOmacWqb6pvEkUPhODKJALG4+9w9MdDvX/2G6w6K0heFcau667GEMKGGQOAJDihjObjMY5n/zKWUeeWlzH/OGXvyo/sulbTPx8SsYLRQghhIzqsbroskAYRBOEuilDOzg9HU+y/f6G9vUMG9DAu+wi5tSZBpNWWsl/IEkDQBQIASUGgJLRtC8+Zq6tfKrJDyk4SzBJS9guGBZALNQCCMBoAoqwqXB5fQjCDpE9zFoO9CSc2EnliJgGkUadXYEa5Z5JADCYICiRisDdm8ElVBEJyAJAASEpdorJL2ZW6RGiBWPKqCDT4JOac75mFayfqKVhgdaZEjFE6NRJBhSKCuT31HuCunHO+Z+NbD54I2h4u92Jq227+YU+pbYDcgrkTcyfkFqltcNJru+FyLwraHt968CQK4C4ONtkMv14ZxPrV019zQnQjbFGBCx6R+NVoqZo1aXKEg00t/v3lnbXBaGW0Jfg6gdgkR3YXAAQzVtEgIBG2s/9uIUyfj7OvJ0fdJb8IAEW019E+H+f/AJvEd3R++Q5SAAAAAElFTkSuQmCC"
          target="_blank"
        >预生产环境-运营端</a>
      </li>  <li>
        <a
          href="http://112.74.164.238:8086/esign/approveabc/doc.html"
          ADD_DATE="1663815179"
          ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAClElEQVQ4jW1TTWtUZxg953nfe2+ccRqdaYiCDnTTFrpz2UW5+AMCXQnuTKnxBwT8DUJ+gCJJFy12KfgDZFAQXLS4NCuhlKqYmJiZxrn3vu9zukgqs/CsHng+zuHhHAKAABIQAPx9/8bVaFrPUN22uggAZcnXAZgkt51LP//yeHGHOMXLO2uD86ujLSVfJxHnXYbkEgmzyKXCIPfEYDsHb/c3v739aAoAFMDd7bWz5zh81CtDvX+cM0MpQPY/geYzMRQugKNBFY6bbnKosPbNTzuzSED/4PxWv4r1u1lq7PvrBc4MDZ4gOULVR//tLg+f/x5C9YX2pk2zMliqfd5uEdjgX3ev/1AU5eOmacWqb6pvEkUPhODKJALG4+9w9MdDvX/2G6w6K0heFcau667GEMKGGQOAJDihjObjMY5n/zKWUeeWlzH/OGXvyo/sulbTPx8SsYLRQghhIzqsbroskAYRBOEuilDOzg9HU+y/f6G9vUMG9DAu+wi5tSZBpNWWsl/IEkDQBQIASUGgJLRtC8+Zq6tfKrJDyk4SzBJS9guGBZALNQCCMBoAoqwqXB5fQjCDpE9zFoO9CSc2EnliJgGkUadXYEa5Z5JADCYICiRisDdm8ElVBEJyAJAASEpdorJL2ZW6RGiBWPKqCDT4JOac75mFayfqKVhgdaZEjFE6NRJBhSKCuT31HuCunHO+Z+NbD54I2h4u92Jq227+YU+pbYDcgrkTcyfkFqltcNJru+FyLwraHt968CQK4C4ONtkMv14ZxPrV019zQnQjbFGBCx6R+NVoqZo1aXKEg00t/v3lnbXBaGW0Jfg6gdgkR3YXAAQzVtEgIBG2s/9uIUyfj7OvJ0fdJb8IAEW019E+H+f/AJvEd3R++Q5SAAAAAElFTkSuQmCC"
          target="_blank"
        >预生产环境-电子签章端</a>
      </li>
      <li class="title">
        开发环境
      </li>
      <li>
        <a
          href="http://120.25.107.214/plat-dev/#/login"
          ADD_DATE="1663815218"
          target="_blank"
        >开发环境-运营端</a>
      </li>
      <li>
        <a
          href="http://120.25.107.214/agent-dev/#/login"
          ADD_DATE="1663815283"
          ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAC7ElEQVQ4jU3TTYjUdRzH8ffn+///Z2efwnVnZ9esQ7Ai0sXKjCI1CKQsyJIOHaPo0IPYrVudO9RNiCA69QShQSASaV3qkARBhomZsu1hn2bN3Z3Zmf/8fp8uHnrfX8e3AAwSGODvVutIcryCdKTGuwSqpAU5X8g5fTLf6fz0fyPudB7G986030/mtSKi3LYJIMNGAyZHJGSonT++vLr81jHoA9J7EM9Cc+d0+8xYEUfXc06gVOIy0C+R/W4KfYhcB1xtEI8MyTd7273j+zY21oofwO9Mz3wwURQvrdh9gYBCUkguatS0OJil8Ql0fbP260XoxlA6+Mx27zf9OTV1qCyqCyGtI03KbmZTK7SRsu8qpBLRy/bouEQ352s3V5f3jcJYDwZllI03E6Q683ko/2F7qmGWJB0o7H+ztdBUrreIvV14O6T5e2dmTsyvrHwJUAoOJ7vfVExtD9PVlL2lCo0QDyR7BehPEuu3c/51oiy15VyT001Ac63W4RjabaHhuNzHVjf3u0VWq53j7C1zrQ5OXKm3/8kQO0IBOren07lkIFUjJ3Wl1R4CvdHQ9zK5xq2wOiENanxPadYkdcEPF2iwUG8/9eitWzfa7V0vuCi+0l8zs9crdF8tI0MCAlEIZDMRQbK5nfM3m1vpjf29tcVWa+6IGtXXzq6j4fRyw75cWpQSTYmGINt0zXon5c8Gw8Fj86vLx/f31hZbs7tPUZXfYqaFzwuonpueffAsw7kl4v5BMFFZnVb272Vn5ZJgFWBubveLWZyEeDw7J6SU+4MnyqchfqyKY4pyHOcv6LFCWQ9ojO1szN79ZFtxSOJolvbYBuduRIyllE53Oss/C6AFk+Xc7otRlg+lYRpKZEOFQsbkbBADjCKiSil9t7a0+DzQKwB1ob+5ufFpc2xyKuMDVlTZlu2hTQIXEVHY5GyfXltafBXoAi7uzCggdTdvnxsZnbyIREg7QHeBhbSQ7TM5+VRnefEjoL5j+A9rlInGhZlwyQAAAABJRU5ErkJggg=="
          target="_blank"
        >开发环境-代采端</a>
      </li>
      <li>
        <a
          href="http://120.25.107.214/cust-dev/#/login"
          ADD_DATE="1663815297"
          ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAC7ElEQVQ4jU3TTYjUdRzH8ffn+///Z2efwnVnZ9esQ7Ai0sXKjCI1CKQsyJIOHaPo0IPYrVudO9RNiCA69QShQSASaV3qkARBhomZsu1hn2bN3Z3Zmf/8fp8uHnrfX8e3AAwSGODvVutIcryCdKTGuwSqpAU5X8g5fTLf6fz0fyPudB7G986030/mtSKi3LYJIMNGAyZHJGSonT++vLr81jHoA9J7EM9Cc+d0+8xYEUfXc06gVOIy0C+R/W4KfYhcB1xtEI8MyTd7273j+zY21oofwO9Mz3wwURQvrdh9gYBCUkguatS0OJil8Ql0fbP260XoxlA6+Mx27zf9OTV1qCyqCyGtI03KbmZTK7SRsu8qpBLRy/bouEQ352s3V5f3jcJYDwZllI03E6Q683ko/2F7qmGWJB0o7H+ztdBUrreIvV14O6T5e2dmTsyvrHwJUAoOJ7vfVExtD9PVlL2lCo0QDyR7BehPEuu3c/51oiy15VyT001Ac63W4RjabaHhuNzHVjf3u0VWq53j7C1zrQ5OXKm3/8kQO0IBOren07lkIFUjJ3Wl1R4CvdHQ9zK5xq2wOiENanxPadYkdcEPF2iwUG8/9eitWzfa7V0vuCi+0l8zs9crdF8tI0MCAlEIZDMRQbK5nfM3m1vpjf29tcVWa+6IGtXXzq6j4fRyw75cWpQSTYmGINt0zXon5c8Gw8Fj86vLx/f31hZbs7tPUZXfYqaFzwuonpueffAsw7kl4v5BMFFZnVb272Vn5ZJgFWBubveLWZyEeDw7J6SU+4MnyqchfqyKY4pyHOcv6LFCWQ9ojO1szN79ZFtxSOJolvbYBuduRIyllE53Oss/C6AFk+Xc7otRlg+lYRpKZEOFQsbkbBADjCKiSil9t7a0+DzQKwB1ob+5ufFpc2xyKuMDVlTZlu2hTQIXEVHY5GyfXltafBXoAi7uzCggdTdvnxsZnbyIREg7QHeBhbSQ7TM5+VRnefEjoL5j+A9rlInGhZlwyQAAAABJRU5ErkJggg=="
          target="_blank"
        >开发环境-经销商端</a>
      </li>
      <li>
        <a
          href="http://120.25.107.214/wms-dev/#/login"
          ADD_DATE="1663815317"
          ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAC7ElEQVQ4jU3TTYjUdRzH8ffn+///Z2efwnVnZ9esQ7Ai0sXKjCI1CKQsyJIOHaPo0IPYrVudO9RNiCA69QShQSASaV3qkARBhomZsu1hn2bN3Z3Zmf/8fp8uHnrfX8e3AAwSGODvVutIcryCdKTGuwSqpAU5X8g5fTLf6fz0fyPudB7G986030/mtSKi3LYJIMNGAyZHJGSonT++vLr81jHoA9J7EM9Cc+d0+8xYEUfXc06gVOIy0C+R/W4KfYhcB1xtEI8MyTd7273j+zY21oofwO9Mz3wwURQvrdh9gYBCUkguatS0OJil8Ql0fbP260XoxlA6+Mx27zf9OTV1qCyqCyGtI03KbmZTK7SRsu8qpBLRy/bouEQ352s3V5f3jcJYDwZllI03E6Q683ko/2F7qmGWJB0o7H+ztdBUrreIvV14O6T5e2dmTsyvrHwJUAoOJ7vfVExtD9PVlL2lCo0QDyR7BehPEuu3c/51oiy15VyT001Ac63W4RjabaHhuNzHVjf3u0VWq53j7C1zrQ5OXKm3/8kQO0IBOren07lkIFUjJ3Wl1R4CvdHQ9zK5xq2wOiENanxPadYkdcEPF2iwUG8/9eitWzfa7V0vuCi+0l8zs9crdF8tI0MCAlEIZDMRQbK5nfM3m1vpjf29tcVWa+6IGtXXzq6j4fRyw75cWpQSTYmGINt0zXon5c8Gw8Fj86vLx/f31hZbs7tPUZXfYqaFzwuonpueffAsw7kl4v5BMFFZnVb272Vn5ZJgFWBubveLWZyEeDw7J6SU+4MnyqchfqyKY4pyHOcv6LFCWQ9ojO1szN79ZFtxSOJolvbYBuduRIyllE53Oss/C6AFk+Xc7otRlg+lYRpKZEOFQsbkbBADjCKiSil9t7a0+DzQKwB1ob+5ufFpc2xyKuMDVlTZlu2hTQIXEVHY5GyfXltafBXoAi7uzCggdTdvnxsZnbyIREg7QHeBhbSQ7TM5+VRnefEjoL5j+A9rlInGhZlwyQAAAABJRU5ErkJggg=="
          target="_blank"
        >开发环境-仓库系统</a>
      </li>
      <li>
        <a
          href="http://120.25.107.214/scm-dev/#/login"
          ADD_DATE="1663815359"
          ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAC7ElEQVQ4jU3TTYjUdRzH8ffn+///Z2efwnVnZ9esQ7Ai0sXKjCI1CKQsyJIOHaPo0IPYrVudO9RNiCA69QShQSASaV3qkARBhomZsu1hn2bN3Z3Zmf/8fp8uHnrfX8e3AAwSGODvVutIcryCdKTGuwSqpAU5X8g5fTLf6fz0fyPudB7G986030/mtSKi3LYJIMNGAyZHJGSonT++vLr81jHoA9J7EM9Cc+d0+8xYEUfXc06gVOIy0C+R/W4KfYhcB1xtEI8MyTd7273j+zY21oofwO9Mz3wwURQvrdh9gYBCUkguatS0OJil8Ql0fbP260XoxlA6+Mx27zf9OTV1qCyqCyGtI03KbmZTK7SRsu8qpBLRy/bouEQ352s3V5f3jcJYDwZllI03E6Q683ko/2F7qmGWJB0o7H+ztdBUrreIvV14O6T5e2dmTsyvrHwJUAoOJ7vfVExtD9PVlL2lCo0QDyR7BehPEuu3c/51oiy15VyT001Ac63W4RjabaHhuNzHVjf3u0VWq53j7C1zrQ5OXKm3/8kQO0IBOren07lkIFUjJ3Wl1R4CvdHQ9zK5xq2wOiENanxPadYkdcEPF2iwUG8/9eitWzfa7V0vuCi+0l8zs9crdF8tI0MCAlEIZDMRQbK5nfM3m1vpjf29tcVWa+6IGtXXzq6j4fRyw75cWpQSTYmGINt0zXon5c8Gw8Fj86vLx/f31hZbs7tPUZXfYqaFzwuonpueffAsw7kl4v5BMFFZnVb272Vn5ZJgFWBubveLWZyEeDw7J6SU+4MnyqchfqyKY4pyHOcv6LFCWQ9ojO1szN79ZFtxSOJolvbYBuduRIyllE53Oss/C6AFk+Xc7otRlg+lYRpKZEOFQsbkbBADjCKiSil9t7a0+DzQKwB1ob+5ufFpc2xyKuMDVlTZlu2hTQIXEVHY5GyfXltafBXoAi7uzCggdTdvnxsZnbyIREg7QHeBhbSQ7TM5+VRnefEjoL5j+A9rlInGhZlwyQAAAABJRU5ErkJggg=="
          target="_blank"
        >开发环境-商城后台</a>
      </li>
      <li>
        <a
          href="http://120.25.107.214/scm-portal-dev/#/login"
          ADD_DATE="1663815324"
          ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAC7ElEQVQ4jU3TTYjUdRzH8ffn+///Z2efwnVnZ9esQ7Ai0sXKjCI1CKQsyJIOHaPo0IPYrVudO9RNiCA69QShQSASaV3qkARBhomZsu1hn2bN3Z3Zmf/8fp8uHnrfX8e3AAwSGODvVutIcryCdKTGuwSqpAU5X8g5fTLf6fz0fyPudB7G986030/mtSKi3LYJIMNGAyZHJGSonT++vLr81jHoA9J7EM9Cc+d0+8xYEUfXc06gVOIy0C+R/W4KfYhcB1xtEI8MyTd7273j+zY21oofwO9Mz3wwURQvrdh9gYBCUkguatS0OJil8Ql0fbP260XoxlA6+Mx27zf9OTV1qCyqCyGtI03KbmZTK7SRsu8qpBLRy/bouEQ352s3V5f3jcJYDwZllI03E6Q683ko/2F7qmGWJB0o7H+ztdBUrreIvV14O6T5e2dmTsyvrHwJUAoOJ7vfVExtD9PVlL2lCo0QDyR7BehPEuu3c/51oiy15VyT001Ac63W4RjabaHhuNzHVjf3u0VWq53j7C1zrQ5OXKm3/8kQO0IBOren07lkIFUjJ3Wl1R4CvdHQ9zK5xq2wOiENanxPadYkdcEPF2iwUG8/9eitWzfa7V0vuCi+0l8zs9crdF8tI0MCAlEIZDMRQbK5nfM3m1vpjf29tcVWa+6IGtXXzq6j4fRyw75cWpQSTYmGINt0zXon5c8Gw8Fj86vLx/f31hZbs7tPUZXfYqaFzwuonpueffAsw7kl4v5BMFFZnVb272Vn5ZJgFWBubveLWZyEeDw7J6SU+4MnyqchfqyKY4pyHOcv6LFCWQ9ojO1szN79ZFtxSOJolvbYBuduRIyllE53Oss/C6AFk+Xc7otRlg+lYRpKZEOFQsbkbBADjCKiSil9t7a0+DzQKwB1ob+5ufFpc2xyKuMDVlTZlu2hTQIXEVHY5GyfXltafBXoAi7uzCggdTdvnxsZnbyIREg7QHeBhbSQ7TM5+VRnefEjoL5j+A9rlInGhZlwyQAAAABJRU5ErkJggg=="
          target="_blank"
        >开发环境-商城门户</a>
      </li>
      <li class="title">
        测试环境
      </li>
      <li>
        <a
          href="http://120.77.45.206/plat-test/#/login"
          ADD_DATE="1663815426"
          target="_blank"
        >测试环境-运营端</a>
      </li>
      <li>
        <a
          href="http://120.77.45.206/agent-test/#/login"
          ADD_DATE="1663815434"
          ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAC7ElEQVQ4jU3TTYjUdRzH8ffn+///Z2efwnVnZ9esQ7Ai0sXKjCI1CKQsyJIOHaPo0IPYrVudO9RNiCA69QShQSASaV3qkARBhomZsu1hn2bN3Z3Zmf/8fp8uHnrfX8e3AAwSGODvVutIcryCdKTGuwSqpAU5X8g5fTLf6fz0fyPudB7G986030/mtSKi3LYJIMNGAyZHJGSonT++vLr81jHoA9J7EM9Cc+d0+8xYEUfXc06gVOIy0C+R/W4KfYhcB1xtEI8MyTd7273j+zY21oofwO9Mz3wwURQvrdh9gYBCUkguatS0OJil8Ql0fbP260XoxlA6+Mx27zf9OTV1qCyqCyGtI03KbmZTK7SRsu8qpBLRy/bouEQ352s3V5f3jcJYDwZllI03E6Q683ko/2F7qmGWJB0o7H+ztdBUrreIvV14O6T5e2dmTsyvrHwJUAoOJ7vfVExtD9PVlL2lCo0QDyR7BehPEuu3c/51oiy15VyT001Ac63W4RjabaHhuNzHVjf3u0VWq53j7C1zrQ5OXKm3/8kQO0IBOren07lkIFUjJ3Wl1R4CvdHQ9zK5xq2wOiENanxPadYkdcEPF2iwUG8/9eitWzfa7V0vuCi+0l8zs9crdF8tI0MCAlEIZDMRQbK5nfM3m1vpjf29tcVWa+6IGtXXzq6j4fRyw75cWpQSTYmGINt0zXon5c8Gw8Fj86vLx/f31hZbs7tPUZXfYqaFzwuonpueffAsw7kl4v5BMFFZnVb272Vn5ZJgFWBubveLWZyEeDw7J6SU+4MnyqchfqyKY4pyHOcv6LFCWQ9ojO1szN79ZFtxSOJolvbYBuduRIyllE53Oss/C6AFk+Xc7otRlg+lYRpKZEOFQsbkbBADjCKiSil9t7a0+DzQKwB1ob+5ufFpc2xyKuMDVlTZlu2hTQIXEVHY5GyfXltafBXoAi7uzCggdTdvnxsZnbyIREg7QHeBhbSQ7TM5+VRnefEjoL5j+A9rlInGhZlwyQAAAABJRU5ErkJggg=="
          target="_blank"
        >测试环境-代采端</a>
      </li>
      <li>
        <a
          href="http://120.77.45.206/cust-test/#/login"
          ADD_DATE="1663815441"
          ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAC7ElEQVQ4jU3TTYjUdRzH8ffn+///Z2efwnVnZ9esQ7Ai0sXKjCI1CKQsyJIOHaPo0IPYrVudO9RNiCA69QShQSASaV3qkARBhomZsu1hn2bN3Z3Zmf/8fp8uHnrfX8e3AAwSGODvVutIcryCdKTGuwSqpAU5X8g5fTLf6fz0fyPudB7G986030/mtSKi3LYJIMNGAyZHJGSonT++vLr81jHoA9J7EM9Cc+d0+8xYEUfXc06gVOIy0C+R/W4KfYhcB1xtEI8MyTd7273j+zY21oofwO9Mz3wwURQvrdh9gYBCUkguatS0OJil8Ql0fbP260XoxlA6+Mx27zf9OTV1qCyqCyGtI03KbmZTK7SRsu8qpBLRy/bouEQ352s3V5f3jcJYDwZllI03E6Q683ko/2F7qmGWJB0o7H+ztdBUrreIvV14O6T5e2dmTsyvrHwJUAoOJ7vfVExtD9PVlL2lCo0QDyR7BehPEuu3c/51oiy15VyT001Ac63W4RjabaHhuNzHVjf3u0VWq53j7C1zrQ5OXKm3/8kQO0IBOren07lkIFUjJ3Wl1R4CvdHQ9zK5xq2wOiENanxPadYkdcEPF2iwUG8/9eitWzfa7V0vuCi+0l8zs9crdF8tI0MCAlEIZDMRQbK5nfM3m1vpjf29tcVWa+6IGtXXzq6j4fRyw75cWpQSTYmGINt0zXon5c8Gw8Fj86vLx/f31hZbs7tPUZXfYqaFzwuonpueffAsw7kl4v5BMFFZnVb272Vn5ZJgFWBubveLWZyEeDw7J6SU+4MnyqchfqyKY4pyHOcv6LFCWQ9ojO1szN79ZFtxSOJolvbYBuduRIyllE53Oss/C6AFk+Xc7otRlg+lYRpKZEOFQsbkbBADjCKiSil9t7a0+DzQKwB1ob+5ufFpc2xyKuMDVlTZlu2hTQIXEVHY5GyfXltafBXoAi7uzCggdTdvnxsZnbyIREg7QHeBhbSQ7TM5+VRnefEjoL5j+A9rlInGhZlwyQAAAABJRU5ErkJggg=="
          target="_blank"
        >测试环境-经销商端</a>
      </li>
      <li>
        <a
          href="http://120.77.45.206/wms-test/#/login"
          ADD_DATE="1663815449"
          ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAC7ElEQVQ4jU3TTYjUdRzH8ffn+///Z2efwnVnZ9esQ7Ai0sXKjCI1CKQsyJIOHaPo0IPYrVudO9RNiCA69QShQSASaV3qkARBhomZsu1hn2bN3Z3Zmf/8fp8uHnrfX8e3AAwSGODvVutIcryCdKTGuwSqpAU5X8g5fTLf6fz0fyPudB7G986030/mtSKi3LYJIMNGAyZHJGSonT++vLr81jHoA9J7EM9Cc+d0+8xYEUfXc06gVOIy0C+R/W4KfYhcB1xtEI8MyTd7273j+zY21oofwO9Mz3wwURQvrdh9gYBCUkguatS0OJil8Ql0fbP260XoxlA6+Mx27zf9OTV1qCyqCyGtI03KbmZTK7SRsu8qpBLRy/bouEQ352s3V5f3jcJYDwZllI03E6Q683ko/2F7qmGWJB0o7H+ztdBUrreIvV14O6T5e2dmTsyvrHwJUAoOJ7vfVExtD9PVlL2lCo0QDyR7BehPEuu3c/51oiy15VyT001Ac63W4RjabaHhuNzHVjf3u0VWq53j7C1zrQ5OXKm3/8kQO0IBOren07lkIFUjJ3Wl1R4CvdHQ9zK5xq2wOiENanxPadYkdcEPF2iwUG8/9eitWzfa7V0vuCi+0l8zs9crdF8tI0MCAlEIZDMRQbK5nfM3m1vpjf29tcVWa+6IGtXXzq6j4fRyw75cWpQSTYmGINt0zXon5c8Gw8Fj86vLx/f31hZbs7tPUZXfYqaFzwuonpueffAsw7kl4v5BMFFZnVb272Vn5ZJgFWBubveLWZyEeDw7J6SU+4MnyqchfqyKY4pyHOcv6LFCWQ9ojO1szN79ZFtxSOJolvbYBuduRIyllE53Oss/C6AFk+Xc7otRlg+lYRpKZEOFQsbkbBADjCKiSil9t7a0+DzQKwB1ob+5ufFpc2xyKuMDVlTZlu2hTQIXEVHY5GyfXltafBXoAi7uzCggdTdvnxsZnbyIREg7QHeBhbSQ7TM5+VRnefEjoL5j+A9rlInGhZlwyQAAAABJRU5ErkJggg=="
          target="_blank"
        >测试环境-仓库系统</a>
      </li>
      <li>
        <a
          href="http://120.77.45.206/scm-portal-test/#/login"
          ADD_DATE="1663815454"
          ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAC7ElEQVQ4jU3TTYjUdRzH8ffn+///Z2efwnVnZ9esQ7Ai0sXKjCI1CKQsyJIOHaPo0IPYrVudO9RNiCA69QShQSASaV3qkARBhomZsu1hn2bN3Z3Zmf/8fp8uHnrfX8e3AAwSGODvVutIcryCdKTGuwSqpAU5X8g5fTLf6fz0fyPudB7G986030/mtSKi3LYJIMNGAyZHJGSonT++vLr81jHoA9J7EM9Cc+d0+8xYEUfXc06gVOIy0C+R/W4KfYhcB1xtEI8MyTd7273j+zY21oofwO9Mz3wwURQvrdh9gYBCUkguatS0OJil8Ql0fbP260XoxlA6+Mx27zf9OTV1qCyqCyGtI03KbmZTK7SRsu8qpBLRy/bouEQ352s3V5f3jcJYDwZllI03E6Q683ko/2F7qmGWJB0o7H+ztdBUrreIvV14O6T5e2dmTsyvrHwJUAoOJ7vfVExtD9PVlL2lCo0QDyR7BehPEuu3c/51oiy15VyT001Ac63W4RjabaHhuNzHVjf3u0VWq53j7C1zrQ5OXKm3/8kQO0IBOren07lkIFUjJ3Wl1R4CvdHQ9zK5xq2wOiENanxPadYkdcEPF2iwUG8/9eitWzfa7V0vuCi+0l8zs9crdF8tI0MCAlEIZDMRQbK5nfM3m1vpjf29tcVWa+6IGtXXzq6j4fRyw75cWpQSTYmGINt0zXon5c8Gw8Fj86vLx/f31hZbs7tPUZXfYqaFzwuonpueffAsw7kl4v5BMFFZnVb272Vn5ZJgFWBubveLWZyEeDw7J6SU+4MnyqchfqyKY4pyHOcv6LFCWQ9ojO1szN79ZFtxSOJolvbYBuduRIyllE53Oss/C6AFk+Xc7otRlg+lYRpKZEOFQsbkbBADjCKiSil9t7a0+DzQKwB1ob+5ufFpc2xyKuMDVlTZlu2hTQIXEVHY5GyfXltafBXoAi7uzCggdTdvnxsZnbyIREg7QHeBhbSQ7TM5+VRnefEjoL5j+A9rlInGhZlwyQAAAABJRU5ErkJggg=="
          target="_blank"
        >测试环境-商城门户</a>
      </li>
      <li>
        <a
          href="http://120.77.45.206/scm-test/#/login"
          ADD_DATE="1663815469"
          ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAC7ElEQVQ4jU3TTYjUdRzH8ffn+///Z2efwnVnZ9esQ7Ai0sXKjCI1CKQsyJIOHaPo0IPYrVudO9RNiCA69QShQSASaV3qkARBhomZsu1hn2bN3Z3Zmf/8fp8uHnrfX8e3AAwSGODvVutIcryCdKTGuwSqpAU5X8g5fTLf6fz0fyPudB7G986030/mtSKi3LYJIMNGAyZHJGSonT++vLr81jHoA9J7EM9Cc+d0+8xYEUfXc06gVOIy0C+R/W4KfYhcB1xtEI8MyTd7273j+zY21oofwO9Mz3wwURQvrdh9gYBCUkguatS0OJil8Ql0fbP260XoxlA6+Mx27zf9OTV1qCyqCyGtI03KbmZTK7SRsu8qpBLRy/bouEQ352s3V5f3jcJYDwZllI03E6Q683ko/2F7qmGWJB0o7H+ztdBUrreIvV14O6T5e2dmTsyvrHwJUAoOJ7vfVExtD9PVlL2lCo0QDyR7BehPEuu3c/51oiy15VyT001Ac63W4RjabaHhuNzHVjf3u0VWq53j7C1zrQ5OXKm3/8kQO0IBOren07lkIFUjJ3Wl1R4CvdHQ9zK5xq2wOiENanxPadYkdcEPF2iwUG8/9eitWzfa7V0vuCi+0l8zs9crdF8tI0MCAlEIZDMRQbK5nfM3m1vpjf29tcVWa+6IGtXXzq6j4fRyw75cWpQSTYmGINt0zXon5c8Gw8Fj86vLx/f31hZbs7tPUZXfYqaFzwuonpueffAsw7kl4v5BMFFZnVb272Vn5ZJgFWBubveLWZyEeDw7J6SU+4MnyqchfqyKY4pyHOcv6LFCWQ9ojO1szN79ZFtxSOJolvbYBuduRIyllE53Oss/C6AFk+Xc7otRlg+lYRpKZEOFQsbkbBADjCKiSil9t7a0+DzQKwB1ob+5ufFpc2xyKuMDVlTZlu2hTQIXEVHY5GyfXltafBXoAi7uzCggdTdvnxsZnbyIREg7QHeBhbSQ7TM5+VRnefEjoL5j+A9rlInGhZlwyQAAAABJRU5ErkJggg=="
          target="_blank"
        >测试环境-商城后台</a>
      </li>
      <li class="title">
        预生产环境
      </li>
      <li>
        <a
          href="http://112.74.164.238/plat-pre/#/login"
          ADD_DATE="1663815469"
          ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAC7ElEQVQ4jU3TTYjUdRzH8ffn+///Z2efwnVnZ9esQ7Ai0sXKjCI1CKQsyJIOHaPo0IPYrVudO9RNiCA69QShQSASaV3qkARBhomZsu1hn2bN3Z3Zmf/8fp8uHnrfX8e3AAwSGODvVutIcryCdKTGuwSqpAU5X8g5fTLf6fz0fyPudB7G986030/mtSKi3LYJIMNGAyZHJGSonT++vLr81jHoA9J7EM9Cc+d0+8xYEUfXc06gVOIy0C+R/W4KfYhcB1xtEI8MyTd7273j+zY21oofwO9Mz3wwURQvrdh9gYBCUkguatS0OJil8Ql0fbP260XoxlA6+Mx27zf9OTV1qCyqCyGtI03KbmZTK7SRsu8qpBLRy/bouEQ352s3V5f3jcJYDwZllI03E6Q683ko/2F7qmGWJB0o7H+ztdBUrreIvV14O6T5e2dmTsyvrHwJUAoOJ7vfVExtD9PVlL2lCo0QDyR7BehPEuu3c/51oiy15VyT001Ac63W4RjabaHhuNzHVjf3u0VWq53j7C1zrQ5OXKm3/8kQO0IBOren07lkIFUjJ3Wl1R4CvdHQ9zK5xq2wOiENanxPadYkdcEPF2iwUG8/9eitWzfa7V0vuCi+0l8zs9crdF8tI0MCAlEIZDMRQbK5nfM3m1vpjf29tcVWa+6IGtXXzq6j4fRyw75cWpQSTYmGINt0zXon5c8Gw8Fj86vLx/f31hZbs7tPUZXfYqaFzwuonpueffAsw7kl4v5BMFFZnVb272Vn5ZJgFWBubveLWZyEeDw7J6SU+4MnyqchfqyKY4pyHOcv6LFCWQ9ojO1szN79ZFtxSOJolvbYBuduRIyllE53Oss/C6AFk+Xc7otRlg+lYRpKZEOFQsbkbBADjCKiSil9t7a0+DzQKwB1ob+5ufFpc2xyKuMDVlTZlu2hTQIXEVHY5GyfXltafBXoAi7uzCggdTdvnxsZnbyIREg7QHeBhbSQ7TM5+VRnefEjoL5j+A9rlInGhZlwyQAAAABJRU5ErkJggg=="
          target="_blank"
        >运营端</a>
      </li>
      <li>
        <a
          href="http://112.74.164.238/agent-pre/#/login"
          ADD_DATE="1663815469"
          ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAC7ElEQVQ4jU3TTYjUdRzH8ffn+///Z2efwnVnZ9esQ7Ai0sXKjCI1CKQsyJIOHaPo0IPYrVudO9RNiCA69QShQSASaV3qkARBhomZsu1hn2bN3Z3Zmf/8fp8uHnrfX8e3AAwSGODvVutIcryCdKTGuwSqpAU5X8g5fTLf6fz0fyPudB7G986030/mtSKi3LYJIMNGAyZHJGSonT++vLr81jHoA9J7EM9Cc+d0+8xYEUfXc06gVOIy0C+R/W4KfYhcB1xtEI8MyTd7273j+zY21oofwO9Mz3wwURQvrdh9gYBCUkguatS0OJil8Ql0fbP260XoxlA6+Mx27zf9OTV1qCyqCyGtI03KbmZTK7SRsu8qpBLRy/bouEQ352s3V5f3jcJYDwZllI03E6Q683ko/2F7qmGWJB0o7H+ztdBUrreIvV14O6T5e2dmTsyvrHwJUAoOJ7vfVExtD9PVlL2lCo0QDyR7BehPEuu3c/51oiy15VyT001Ac63W4RjabaHhuNzHVjf3u0VWq53j7C1zrQ5OXKm3/8kQO0IBOren07lkIFUjJ3Wl1R4CvdHQ9zK5xq2wOiENanxPadYkdcEPF2iwUG8/9eitWzfa7V0vuCi+0l8zs9crdF8tI0MCAlEIZDMRQbK5nfM3m1vpjf29tcVWa+6IGtXXzq6j4fRyw75cWpQSTYmGINt0zXon5c8Gw8Fj86vLx/f31hZbs7tPUZXfYqaFzwuonpueffAsw7kl4v5BMFFZnVb272Vn5ZJgFWBubveLWZyEeDw7J6SU+4MnyqchfqyKY4pyHOcv6LFCWQ9ojO1szN79ZFtxSOJolvbYBuduRIyllE53Oss/C6AFk+Xc7otRlg+lYRpKZEOFQsbkbBADjCKiSil9t7a0+DzQKwB1ob+5ufFpc2xyKuMDVlTZlu2hTQIXEVHY5GyfXltafBXoAi7uzCggdTdvnxsZnbyIREg7QHeBhbSQ7TM5+VRnefEjoL5j+A9rlInGhZlwyQAAAABJRU5ErkJggg=="
          target="_blank"
        >代采端</a>
      </li>
      <li>
        <a
          href="http://112.74.164.238/cust-pre/#/login"
          ADD_DATE="1663815469"
          ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAC7ElEQVQ4jU3TTYjUdRzH8ffn+///Z2efwnVnZ9esQ7Ai0sXKjCI1CKQsyJIOHaPo0IPYrVudO9RNiCA69QShQSASaV3qkARBhomZsu1hn2bN3Z3Zmf/8fp8uHnrfX8e3AAwSGODvVutIcryCdKTGuwSqpAU5X8g5fTLf6fz0fyPudB7G986030/mtSKi3LYJIMNGAyZHJGSonT++vLr81jHoA9J7EM9Cc+d0+8xYEUfXc06gVOIy0C+R/W4KfYhcB1xtEI8MyTd7273j+zY21oofwO9Mz3wwURQvrdh9gYBCUkguatS0OJil8Ql0fbP260XoxlA6+Mx27zf9OTV1qCyqCyGtI03KbmZTK7SRsu8qpBLRy/bouEQ352s3V5f3jcJYDwZllI03E6Q683ko/2F7qmGWJB0o7H+ztdBUrreIvV14O6T5e2dmTsyvrHwJUAoOJ7vfVExtD9PVlL2lCo0QDyR7BehPEuu3c/51oiy15VyT001Ac63W4RjabaHhuNzHVjf3u0VWq53j7C1zrQ5OXKm3/8kQO0IBOren07lkIFUjJ3Wl1R4CvdHQ9zK5xq2wOiENanxPadYkdcEPF2iwUG8/9eitWzfa7V0vuCi+0l8zs9crdF8tI0MCAlEIZDMRQbK5nfM3m1vpjf29tcVWa+6IGtXXzq6j4fRyw75cWpQSTYmGINt0zXon5c8Gw8Fj86vLx/f31hZbs7tPUZXfYqaFzwuonpueffAsw7kl4v5BMFFZnVb272Vn5ZJgFWBubveLWZyEeDw7J6SU+4MnyqchfqyKY4pyHOcv6LFCWQ9ojO1szN79ZFtxSOJolvbYBuduRIyllE53Oss/C6AFk+Xc7otRlg+lYRpKZEOFQsbkbBADjCKiSil9t7a0+DzQKwB1ob+5ufFpc2xyKuMDVlTZlu2hTQIXEVHY5GyfXltafBXoAi7uzCggdTdvnxsZnbyIREg7QHeBhbSQ7TM5+VRnefEjoL5j+A9rlInGhZlwyQAAAABJRU5ErkJggg=="
          target="_blank"
        >经销商端</a>
      </li>
      <li>
        <a
          href="http://112.74.164.238/wms-pre/#/login"
          ADD_DATE="1663815469"
          ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAC7ElEQVQ4jU3TTYjUdRzH8ffn+///Z2efwnVnZ9esQ7Ai0sXKjCI1CKQsyJIOHaPo0IPYrVudO9RNiCA69QShQSASaV3qkARBhomZsu1hn2bN3Z3Zmf/8fp8uHnrfX8e3AAwSGODvVutIcryCdKTGuwSqpAU5X8g5fTLf6fz0fyPudB7G986030/mtSKi3LYJIMNGAyZHJGSonT++vLr81jHoA9J7EM9Cc+d0+8xYEUfXc06gVOIy0C+R/W4KfYhcB1xtEI8MyTd7273j+zY21oofwO9Mz3wwURQvrdh9gYBCUkguatS0OJil8Ql0fbP260XoxlA6+Mx27zf9OTV1qCyqCyGtI03KbmZTK7SRsu8qpBLRy/bouEQ352s3V5f3jcJYDwZllI03E6Q683ko/2F7qmGWJB0o7H+ztdBUrreIvV14O6T5e2dmTsyvrHwJUAoOJ7vfVExtD9PVlL2lCo0QDyR7BehPEuu3c/51oiy15VyT001Ac63W4RjabaHhuNzHVjf3u0VWq53j7C1zrQ5OXKm3/8kQO0IBOren07lkIFUjJ3Wl1R4CvdHQ9zK5xq2wOiENanxPadYkdcEPF2iwUG8/9eitWzfa7V0vuCi+0l8zs9crdF8tI0MCAlEIZDMRQbK5nfM3m1vpjf29tcVWa+6IGtXXzq6j4fRyw75cWpQSTYmGINt0zXon5c8Gw8Fj86vLx/f31hZbs7tPUZXfYqaFzwuonpueffAsw7kl4v5BMFFZnVb272Vn5ZJgFWBubveLWZyEeDw7J6SU+4MnyqchfqyKY4pyHOcv6LFCWQ9ojO1szN79ZFtxSOJolvbYBuduRIyllE53Oss/C6AFk+Xc7otRlg+lYRpKZEOFQsbkbBADjCKiSil9t7a0+DzQKwB1ob+5ufFpc2xyKuMDVlTZlu2hTQIXEVHY5GyfXltafBXoAi7uzCggdTdvnxsZnbyIREg7QHeBhbSQ7TM5+VRnefEjoL5j+A9rlInGhZlwyQAAAABJRU5ErkJggg=="
          target="_blank"
        >仓库端</a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss" scoped>
.projectAddressPageBox {
  width: 1500px;
  height: auto;
  margin: auto;
  li {
    list-style: none;
    width: 300px;
    height: 50px;
    display: inline-block;
    border: 1px solid #eee;
    margin: 5px;
    text-align: center;
    line-height: 50px;
  }
  a {
    text-decoration: none;
    display: block;
    width: 100%;
    height: 100%;
    color: black;
  }
  ul {
    margin: 0;
  }
  .title {
    text-align: left;
    line-height: 50px;
    display: block;
    border: none;
    font-size: 20px;
    font-weight: 700;
  }
  li a:hover {
    background-color: #eee;
    color: rgb(7, 10, 163);
    font-weight: 700;
  } /* 鼠标移动到链接上 */
}
</style>
